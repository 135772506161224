'use client'
import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
// Zod is the validation framework - generates types based on schema (plays nice w Typescript)
import { zodResolver } from '@hookform/resolvers/zod'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Modal from '@components/modal'
import TextField from '@components/text-field'
import RecaptchaLegal from '@components/recaptcha-legal'
// This is the validation schema we are using in the form
import { FoundationNewsletterSubscriptionSchema } from '../schemas/foundation-newsletter-subscription'

import type { Dispatch, SetStateAction } from 'react'
import type { FoundationNewsletterSubscriptionType } from '../schemas/foundation-newsletter-subscription'
import { Button, Icon, Typography } from '@shc/ui'
import { AnalyticsLink } from '@components/analytics'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import { BlockContext, PageContext } from '@lib/analytics'

/**
 * The post to /api/give-subscriptions will send a message to an SQS queue.
 * The API gateway will validate the request and send a 200 upon success.
 * A lambda will trigger from the queue and verify the google recaptcha, if
 * successful the lambda will then trigger two sequential email messages, both brokered by the Mailgun service.
 */

type FoundationNewsletterSignupProps = {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const FoundationNewsletterSignup = ({
  isModalOpen = false,
  setIsModalOpen,
}: FoundationNewsletterSignupProps) => {
  const { trackSnowplow } = useAnalytics()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const blockContext = useContext(BlockContext)
  const pageContext = useContext(PageContext)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FoundationNewsletterSubscriptionType>({
    mode: 'onSubmit',
    // criteriaMode: "all",
    reValidateMode: 'onChange',

    resolver: zodResolver(FoundationNewsletterSubscriptionSchema),
  })
  let firstInputFieldRef = useRef<HTMLInputElement | null>(null)

  const handleReCaptchaVerify: SubmitHandler<FoundationNewsletterSubscriptionType> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        // guard statement
        console.log('Execute recaptcha not yet available')
        setIsError(true)
        return
      }

      try {
        // action is a metric - use foundation-newsletter
        const token = await executeRecaptcha('foundation_subscribe')
        setIsError(false)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...data,
            captcha_token: token,
          }),
        }
        // fetch is called and apiFoundationNewsletterSubscriptionsHandler at /api/foundation-newsletter-subscriptions is invoked (this is the lambda)
        const response = await fetch('/api/foundation-newsletter-subscriptions', requestOptions)
        if (response.status !== 200) {
          console.error({ message: response.statusText, status: response.status, data })
          throw new Error('Newsletter subscription failed')
        }
        // success
        setIsSuccess(true)
        const contexts: SPContext[] = [
          {
            name: 'component',
            data: {
              component_text: 'Subscribe',
            },
          },
          { name: 'modal', data: { modal_name: 'Foundation Newsletter' } },
        ]
        if (pageContext) {
          contexts.push(pageContext)
        }
        if (blockContext) {
          contexts.push(blockContext)
        }
        trackSnowplow({
          event: { name: 'newsletter_submit', data: {} },
          contexts,
        })
      } catch {
        setIsError(true)
      }
    },
    [blockContext, executeRecaptcha, pageContext, trackSnowplow]
  )

  // reset state on close
  useEffect(() => {
    if (!isModalOpen) {
      reset()
      setIsSuccess(false)
      setIsError(false)
    }
  }, [isModalOpen, reset])

  return (
    <Modal
      size="md"
      contentName="Newsletter"
      initialFocus={firstInputFieldRef}
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      className="flex flex-col">
      <Icon icon="envelope" className="text-primary h-8 md:h-10" />
      <Modal.Title as={Typography} variant="h2" align="center" className="mt-6">
        Sign up for our newsletter
      </Modal.Title>

      {/* handleSubmit comes from ReactHook Forms */}
      <form name="newsletter signup form" onSubmit={handleSubmit(handleReCaptchaVerify)} noValidate>
        <div className="flex flex-col gap-y-6 mt-8">
          <TextField
            // ReactHook construct returns ref and other stuff
            {...register('email')}
            ref={(e: HTMLInputElement) => {
              firstInputFieldRef.current = e
              register('email').ref(e)
            }}
            label="Email"
            required={true}
            isError={!!errors['email'] && !isSubmitSuccessful}
            validationText={errors['email']?.message}
          />
          <TextField
            {...register('first_name')}
            label="First name"
            required={true}
            isError={!!errors['first_name'] && !isSubmitSuccessful}
            validationText={errors['first_name']?.message}
          />
          <TextField
            {...register('last_name')}
            label="Last name"
            required={true}
            isError={!!errors['last_name'] && !isSubmitSuccessful}
            validationText={errors['last_name']?.message}
          />
        </div>

        <p className="mt-8 text-left text-sm">
          By clicking &ldquo;Subscribe,&rdquo; I agree to receive emails from Sharp HealthCare and
          understand that I may opt out at any time. Read our&nbsp;
          <AnalyticsLink
            href="/patient-rights-privacy/terms-of-use.cfm"
            target="_blank"
            noUnderline
            snowplow={{
              contexts: [{ name: 'modal', data: { modal_name: 'Foundation Newsletter' } }],
            }}>
            Terms of Use
          </AnalyticsLink>
          .
        </p>

        <RecaptchaLegal className="text-sm mt-6" />

        <div className="m-auto text-center mt-8 md:mt-10">
          {!isSuccess && (
            <Button
              type="submit"
              onClick={() => trigger()}
              name="subscribe"
              width="full"
              className="md:w-[310px] md:mx-auto"
              disabled={isSuccess}>
              Subscribe
            </Button>
          )}

          {isSuccess && (
            <>
              <Icon icon="circle-check" className="text-primary h-15" />
              <p className="text-primary font-semibold mt-6" role="status">
                Thank you for subscribing.
              </p>
            </>
          )}
          {isError && (
            <Typography variant="body-semibold" className="text-danger mt-6" role="status">
              We were unable to submit your request. Please try again.
            </Typography>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default FoundationNewsletterSignup
