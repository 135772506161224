'use client'
import React from 'react'
import Image from 'next/image'
import createContentfulLoader from '@lib/contentful-image-loader'
import type { ContentfulImagesAPI } from '@lib/types'
import type * as Contentful from 'contentful'
import clsx from 'clsx'
import { imageSizes } from '@lib/token-maps'
import { useAppConfig } from '@components/client-wrapper'

export interface GenericImage {
  urlString?: string
  altTag?: string
  focalPoint?: string
  className?: string
}

export interface ResponsiveImageProps {
  image: Contentful.Asset | GenericImage
  columnWidthMax?: '16%' | '20%' | '25%' | '33%' | '50%' | '100%' // corresponds to 6,5,4,3,2-up and full width layouts - see imageSizes in token-maps
  className?: string
  imageClassName?: string
  focalPoint?: ContentfulImagesAPI['f']
  priority?: boolean
  heightMap?: { width: number; height: number }[]
  heightScaler?: number
}

function ResponsiveImage({
  image,
  columnWidthMax = '100%',
  className,
  imageClassName,
  focalPoint,
  priority = false,
  heightMap,
  heightScaler,
}: Readonly<ResponsiveImageProps>): JSX.Element {
  const appConfig = useAppConfig()
  const responsiveImageFixFF = appConfig.flags.responsiveImageFix
  let src = ''
  let altText = ''

  // This specifically checks for a 'fields' key and by association, a Contentful.Asset type
  if ('fields' in image) {
    src = image?.fields?.file?.url as string
    altText = image?.fields.title as string
  } else {
    src = image?.urlString as string
    altText = image?.altTag as string
  }

  const sizes = imageSizes[columnWidthMax].join(', ')

  const contentfulLoaderProps: ContentfulImagesAPI = {
    fm: 'webp',
    fit: 'fill',
    f: focalPoint,
    q: 80,
  }

  const contentfulLoader = responsiveImageFixFF
    ? createContentfulLoader(contentfulLoaderProps, heightMap, heightScaler)
    : createContentfulLoader(contentfulLoaderProps)

  return (
    <div
      className={clsx(className, 'relative block overflow-hidden')}
      data-testid="responsive-image-wrapper">
      {/* Please be aware that the NextJS Image component generates the srcset attribute for you OOTB */}
      <Image
        src={src}
        alt={altText}
        fill
        loader={contentfulLoader}
        sizes={sizes}
        priority={priority}
        className={clsx(imageClassName, 'object-cover w-full')}
      />
    </div>
  )
}

export default ResponsiveImage
